<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Settings" subtitle="Platform instellingen" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <button @click="reindexJobs()">reindex jobs</button>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <button @click="reindexCvs()">reindex all cv's</button>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <button @click="reindexUpdates()">reindex all updates</button>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <button @click="reindexPeople()">reindex all people for smoelenboek</button>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

              <div>
                {{result}}
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </main>
</template>

<script>
import JobsService from '../../../services/admin/jobs/service';
import SettingsService from '../../../services/admin/settings/service';
import CvService from '../../../services/admin/cv/service';
import NewsItemService from '../../../services/admin/news_items/service';

export default {
  name: 'Settings',
  data() {
    return {
      result: {result: false, message: ''},
    };
  },
  methods: {
    reindexJobs() {
      JobsService.reindex().then(
        response => {
          this.result = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    reindexUpdates() {
      NewsItemService.reindex().then(
        response => {
          this.result = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    
    reindexCvs() {
      CvService.reindex().then(
        response => {
          this.result = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    reindexPeople() {
      SettingsService.reindexPeople().then(
        response => {
          this.result = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  }
};
</script>

import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class SettingsService {
  
  reindexPeople() {
    return axios.get(API_URL + `admin/settings/reindexUsers`, { headers: authHeader() });
  }

}

export default new SettingsService();
